<template>
  <div class="header-bar">
    <div class="section-title">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  .section-title {
    h1 {
      font-size: 30px;
      font-weight: 600;
      user-select: none;

      @include respond(mobile) {
        text-align: left;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }
    }
  }
}
</style>
