<template>
  <div class="wide-column extra-top-padding page-bottom-padding">
    <page-title title="Mi perfil" />

    <tabs-container>
      <tab-content title="General">
        <div class="profile-details">
          <div class="profile-details__header">
            <p>{{ $t('user_profile.personal_information') }}</p>
            <button
              v-show="!isEditingProfile"
              @click="editUserInfo('profile')"
            >
              {{ $t('global.edit') }}
            </button>
            <div
              v-show="isEditingProfile"
              class="profile-details__buttons-group"
            >
              <button
                @click="cancelEdition('profile')"
              >
                {{ $t('global.cancel') }}
              </button>
              <button
                :class="{ 'disable': !isSaveButtonEnabled }"
                @click="updateProfile()"
              >
                {{ $t('global.save') }}
              </button>
            </div>
          </div>

          <ValidationObserver ref="profile">
            <ul class="profile-details__rows">
              <!--
              <li class="picture">
                <div class="label">
                  Foto de perfil
                </div>
                <div>
                  <avatar-letter :user-data="userData" />
                </div>
              </li>
              -->
              <li class="firstname">
                <div class="label">
                  {{ $t('global.name') }}
                </div>
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="!isEditingProfile"
                    key="1"
                  >
                    {{ getFirstName }}
                  </div>
                  <div
                    v-else
                    key="2"
                  >
                    <ValidationProvider
                      v-slot="{ errors, classes }"
                      rules="required"
                      name="firstname"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="{'Debes ingresar un nombre válido': errors[0],}"
                      >
                        <b-input
                          v-model="user.firstName"
                          type="text"
                          maxlength="100"
                          name="firstname"
                          :class="classes"
                          placeholder="Ingresá tu nombre"
                          @input="onInputChange"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>
                </transition>
              </li>
              <li class="lastname">
                <div class="label">
                  Apellido
                </div>
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="!isEditingProfile"
                    key="1"
                  >
                    {{ getLastName }}
                  </div>
                  <div
                    v-else
                    key="2"
                  >
                    <ValidationProvider
                      v-slot="{ errors, classes }"
                      rules="required"
                      name="lastname"
                    >
                      <b-field
                        :type="{ 'is-danger': errors[0] }"
                        :message="{'Debes ingresar un apellido válido': errors[0],}"
                      >
                        <b-input
                          v-model="user.lastName"
                          type="text"
                          maxlength="100"
                          name="lastname"
                          :class="classes"
                          placeholder="Ingresá tu apellido"
                          @input="onInputChange"
                        />
                      </b-field>
                    </ValidationProvider>
                  </div>
                </transition>
              </li>
              <li
                v-if="!isParticipant"
                class="company"
              >
                <div class="label">
                  Empresa
                </div>
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="!isEditingProfile"
                    key="1"
                  >
                    {{ getCompany }}
                  </div>
                  <div
                    v-else
                    key="2"
                  >
                    <b-field>
                      <b-input
                        v-model="user.company"
                        type="text"
                        maxlength="150"
                        name="company"
                        placeholder="Ingresá tu empresa (opcional)"
                        @input="onInputChange"
                      />
                    </b-field>
                  </div>
                </transition>
              </li>
              <li class="email">
                <div class="label">
                  Correo electrónico
                </div>
                <div>{{ getEmail }}</div>
              </li>
            </ul>
          </ValidationObserver>
        </div>
      </tab-content>

      <tab-content title="Seguridad">
        <div class="profile-details">
          <div class="profile-details__header">
            <p>Cambiar Contraseña</p>
            <button
              v-show="!isEditingSecurity"
              @click="editUserInfo('security')"
            >
              Editar
            </button>
            <div
              v-show="isEditingSecurity"
              class="profile-details__buttons-group"
            >
              <button @click="cancelEdition('security')">
                Cancelar
              </button>
              <button
                :class="{ 'disable': !isSaveButtonEnabled }"
                @click="changePassword()"
              >
                Cambiar
              </button>
            </div>
          </div>

          <ValidationObserver ref="security">
            <ul class="profile-details__rows">
              <transition
                name="fade"
                mode="out-in"
              >
                <li
                  v-if="!isEditingSecurity"
                  key="1"
                  class="password"
                >
                  <div class="label">
                    Contraseña
                  </div>
                  <div>
                    *******
                  </div>
                </li>

                <li
                  v-else
                  key="2"
                  class="change-password"
                >
                  <div class="password-input-group">
                    <div class="col">
                      <ValidationProvider
                        ref="current-password"
                        v-slot="{ errors, invalid }"
                        rules="required"
                        name="Current Password"
                      >
                        <b-field
                          label="Contraseña actual"
                          :type="{ 'is-danger': submitted && invalid }"
                          :message="submitted ? errors : []"
                        >
                          <b-input
                            v-model="current_password"
                            type="password"
                            name="current-password"
                            placeholder="Ingresá tu contraseña actual"
                            password-reveal
                            @input="onInputChange"
                          />
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="col">
                      <ValidationProvider
                        ref="password"
                        v-slot="{ errors, invalid }"
                        rules="required|min:8"
                        vid="password"
                        name="Password"
                      >
                        <b-field
                          label="Nueva contraseña"
                          :type="{ 'is-danger': submitted && invalid }"
                          :message="submitted ? errors : []"
                        >
                          <b-input
                            v-model="password"
                            type="password"
                            placeholder="Ingresá tu nueva contraseña"
                            validation-message="Ingresa una Contraseña válida."
                            password-reveal
                            required
                            @input="onInputChange"
                          />
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div class="col">
                      <ValidationProvider
                        ref="confirmation"
                        v-slot="{ errors, invalid }"
                        rules="required|password:@password"
                        name="confirmation"
                      >
                        <b-field
                          label="Repetir contraseña"
                          :type="{ 'is-danger': submitted && invalid }"
                          :message="submitted ? errors : []"
                        >
                          <b-input
                            v-model="confirmation"
                            type="password"
                            placeholder="Ingresá tu nueva contraseña"
                            validation-message="Ingresa una Contraseña válida."
                            password-reveal
                            required
                            @input="onInputChange"
                          />
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <form
                    ref="confirmationForm"
                    class="form"
                    novalidate
                    @submit.prevent="submitForm"
                  >
                    <input
                      type="email"
                      :value="userData.email"
                      class="hidden"
                    >
                    <input
                      type="password"
                      :value="password"
                      class="hidden"
                    >
                    <button
                      ref="submitButton"
                      type="submit"
                      class="hidden"
                    />
                  </form>
                  <div class="password-rules-message-container">
                    <password-rules-message />
                  </div>
                </li>
              </transition>
            </ul>
          </ValidationObserver>
        </div>
      </tab-content>
    </tabs-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { min, required } from 'vee-validate/dist/rules'

import PasswordRulesMessage from '../PasswordRulesMessage.vue'

import PageTitle from '../ui/PageTitle.vue'
import TabContent from '../ui/TabContent.vue'
import TabsContainer from '../ui/TabsContainer.vue'

extend('min', {
  ...min,
  message: 'La contraseña debe tener al menos 8 caracteres'
})

extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'La contraseña y confirmación no coinciden'
})

extend('upCase', {
  validate(value) {
    return value.match(/[A-Z]/g) !== null
  },
  message: 'La contraseña debe tener al menos 1 mayúscula'
})

extend('number', {
  validate(value) {
    return value.match(/[0-9]/g) !== null
  },
  message: 'La contraseña debe tener al menos 1 número'
})

export default {
  name: 'Profile',
  components: {
    PageTitle,
    PasswordRulesMessage,
    TabContent,
    TabsContainer,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      editingProfile: false,
      editingSecurity: false,
      saveButtonStatus: false,
      submitted: false,

      user: {
        firstName: '',
        lastName: '',
        company: ''
      },

      oldUser: {
        firstName: '',
        lastName: '',
        company: ''
      },

      current_password: null,
      password: null,
      confirmation: null
    }
  },
  computed: {
    ...mapState('Users', ['userData']),

    isEditingProfile() {
      return this.editingProfile
    },

    isEditingSecurity() {
      return this.editingSecurity
    },

    isSaveButtonEnabled() {
      return this.saveButtonStatus
    },

    getFirstName() {
      return this.user.firstName
    },

    getLastName() {
      return this.user.lastName
    },

    getCompany() {
      return this.user.company
    },

    getEmail() {
      return this.userData.email
    }
  },
  created() {
    this.user.firstName = this.userData.first_name
    this.user.lastName = this.userData.last_name
    this.user.company = this.userData.company
  },
  methods: {
    handleEditionButton(tab) {
      if (tab === 'profile') {
        this.editingProfile = !this.editingProfile
      } else if (tab === 'security') {
        this.editingSecurity = !this.editingSecurity
      }
    },

    editUserInfo(tab) {
      if (tab === 'profile') {
        this.oldUser.firstName = this.user.firstName
        this.oldUser.lastName = this.user.lastName
        this.oldUser.company = this.user.company
      }

      this.handleEditionButton(tab)
    },

    cancelEdition(tab) {
      this.saveButtonStatus = false
      if (tab === 'profile') {
        this.user.firstName = this.oldUser.firstName
        this.user.lastName = this.oldUser.lastName
        this.user.company = this.oldUser.company
      }

      this.handleEditionButton(tab)
    },

    updateProfile() {
      const currentTab = 'profile'
      this.validateFields(currentTab)
        .then(() => {
          const user = {
            first_name: this.user.firstName,
            last_name: this.user.lastName,
            company: this.user.company
          }

          this.$store.dispatch('Users/updateUserProfile', user)
            .then(() => {
              this.handleEditionButton(currentTab)
              this.$store.dispatch('Users/getUser')
              this.saveButtonStatus = false
              this.$buefy.toast.open({
                duration: 2500,
                message: 'Tus datos fueron actualizados correctamente',
                position: 'is-top',
                type: 'is-success'
              })
            })
            .catch(() => {
              this.cancelEdition(currentTab)
              this.handleEditionButton(currentTab)
              this.$buefy.toast.open({
                duration: 2500,
                message: 'ERROR: No se pudieron actualizar tus datos',
                position: 'is-top',
                type: 'is-danger'
              })
            })
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 2500,
            message: 'Completá los campos para actualizar tus datos',
            position: 'is-top',
            type: 'is-danger'
          })
        })
    },

    changePassword() {
      const currentTab = 'security'

      this.saveButtonStatus = false
      this.validateFields(currentTab)
        .then(() => {
          const password = {
            new_password: this.password,
            current_password: this.current_password
          }

          this.$store.dispatch('Users/updateUserPassword', password)
            .then(() => {
              this.handleEditionButton(currentTab)

              this.$refs.submitButton.click()

              this.$buefy.toast.open({
                duration: 2500,
                message: 'Contraseña actualizada correctamente',
                position: 'is-top',
                type: 'is-success'
              })
              this.resetPasswordFields()
            })
            .catch((err) => {
              let errorMessage = 'No se pudo actualizar tu contraseña'
              if (err.response && err.response.status === 400) {
                errorMessage = 'Contraseña actual inválida'
              } else if (err.response && err.response.status === 500) {
                errorMessage = 'Ha ocurrido un error, por favor intenta nuevamente'
              }

              this.$buefy.toast.open({
                duration: 2500,
                message: `${errorMessage}`,
                position: 'is-top',
                type: 'is-danger'
              })
            })
        })
        .catch(() => {
          this.submitted = true
          this.$buefy.toast.open({
            duration: 2500,
            message: 'Completá correctamente los campos para cambiar tu contraseña',
            position: 'is-top',
            type: 'is-danger'
          })
        })
    },

    submitForm() {
      return true
    },

    onInputChange() {
      this.saveButtonStatus = true
    },

    validateFields(observer) {
      if (observer === 'profile') {
        return new Promise((resolve, reject) => {
          this.$refs.profile.validate().then((success) => {
            if (success) {
              resolve(success)
            } else {
              this.scrollToError('.invalid > .is-danger')
              reject(success)
            }
          })
        })
      }

      if (observer === 'security') {
        return new Promise((resolve, reject) => {
          this.$refs.security.validate().then((success) => {
            if (success) {
              resolve(success)
            } else {
              reject(success)
            }
          })
        })
      }

      return null
    },

    resetPasswordFields() {
      this.current_password = null
      this.password = null
      this.confirmation = null
      this.submitted = false
      this.$nextTick(() => {
        this.$refs.security.reset()
      })
    },

    // Scroll to the first error
    scrollToError(selector) {
      this.$nextTick(() => {
        this.$el.querySelectorAll(selector)[0].focus()
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '../../scss/profile_scoped.scss';
</style>

<style lang="scss">
  @import '../../scss/profile.scss';
</style>
