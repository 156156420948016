<template>
  <div>
    <ul class="tabs__header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        class="tab__item"
        :class="{'tab__selected': (index == selectedIndex)}"
        @click="selectTab(index)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TabsContainer',
  data() {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    this.selectTab(0)
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs__header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 35px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;

  > .tab__item {
    min-width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: $black;
    position: relative;
    cursor: pointer;

    @include respond(mobile){
      width: 50%;
    }

    &.tab__selected {
      font-weight: 600;
      color: $blue;

      &:after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: -2px;
        background-color: $blue;
      }
    }
  }
}
</style>
