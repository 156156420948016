<template>
  <div
    v-show="isActive"
    class="tab__content"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TabContent',
  props: {
    title: {
      type: String,
      default: 'Tab'
    }
  },
  data() {
    return {
      isActive: true
    }
  }
}
</script>
<style lang="scss" scoped>
.tab__content {
    text-align: left;
}
</style>
