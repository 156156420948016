<template>
  <div class="general-container">
    <the-navbar />
    <profile />
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue'
import Profile from '../components/user-profile/Profile.vue'

export default {
  name: 'UserProfile',
  components: {
    TheNavbar,
    Profile
  }
}
</script>
